import React from 'react'
import { Services } from './Services/Services'
import { Recomendation } from './Recomendation/Recomendation'


export const GroznyShop: React.FC = () => {
  return (
    <>
      <Services />
     <Recomendation />
    </>
  )
}

