import React from 'react'

export const Description: React.FC = ({ item }) => {
  return (
    <div style={{marginTop: '100px'}}>
      <h2>Описание</h2>
 <div className="description" >
   <p>{item.desc}</p>
{/*<p>ОБЪЯВЛЕНИЕ ОТ ЗАСТРОЙЩИКА ООО "ИНВЕСТ-СТРОЙ"С 1-ГО ИЮЛЯ ПОВЫШЕНИЕ СТОИМОСТИ ЗА КВАДРАТ*/}
{/*  Продается двухкомнатная квартира c видом на центр в новостройке европейского качества по Шейха Али Митаева.*/}
{/*  В шаговой доступности вся необходимая инфраструктура:*/}
{/*  <ul>*/}
{/*    <li>  Учебные заведения;</li>*/}
{/*    <li>  Мечеть "Сердце Чечни";</li>*/}
{/*    <li>  Сквер, аллеи;</li>*/}
{/*    <li>  Кафе и рестораны;</li>*/}
{/*    <li>  Администрация города;</li>*/}
{/*    <li>  Больница и аптеки</li>*/}
{/*  </ul>*/}

{/*  <p>В продаже представлена квартира первой секции многоквартирного дома.</p>*/}

{/*  <p>Квартиры продаются за единовременную оплату, а также, есть РАССРОЧКА С ЛУЧШИМИ УСЛОВИЯМИ НА РЫНКЕ*/}
{/*    - с первоначальным взносом, без взноса и на 5 лет включительно.*/}
{/*    Дата завершения строительства: 1 квартал 2024 года.По всем интересующим вопросам пишите или звоните по указанному номеру.</p>*/}
{/*  Или же напрямую, в офисе отдела продаж (по адресу: улица Космонавтов, 17.)</p>*/}
 </div>
    </div>
  )
}

