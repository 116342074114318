import React from 'react'

export const Breadcrumbs: React.FC = () => {
  return (
    <div>
          <span>{`Главная > Недвижимость > Квартиры > Купить > 2-комнатные`}</span>
    </div>
  )
}

