import React from 'react';
import styles from '../../Pages/Message.module.css';

export const MenuTitle: React.FC = () => {
  return (
    <div className={styles.menu_title}>
     <span>Grozny-Shop</span>
    </div>
  )
}

